import { ChangeEvent, FormEvent } from "react";
import { translate as t } from "../../../helpers/i18n";
import TermAndConditionsDashboardComponent from "../terms-and-conditions-dashboard-component";
import { Button } from "../../atoms/button/index";
import { TERMS_CONDITIONS_LAST_UPDATED } from "../../../helpers/constants";
import CheckboxGroup, { TermsData } from "./checkbox-group";
import GenericBaseModal from "../modal/generic-base-modal";
import ModalButtonsWrapper from "../modal/components/modal-buttons-wrapper";

interface AcceptTCModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
  isFailure: boolean;
  isUpdatedTermsAndConditions?: boolean;
  termsData: TermsData;
  handleChange: (
    e: ChangeEvent<HTMLInputElement>,
    currentValue: boolean
  ) => void;
}

const AcceptTCModal = ({
  show,
  onClose,
  onSubmit,
  isLoading,
  isFailure,
  isUpdatedTermsAndConditions,
  handleChange,
  termsData,
}: AcceptTCModalProps) => (
  <GenericBaseModal
    show={show}
    onClose={onClose}
    onCloseButtonClick={onClose}
    fullscreen="md-down"
    title={
      isUpdatedTermsAndConditions
        ? t("updated_terms_conditions.modal_title")
        : t("terms_conditions.modal_title")
    }
    buttons={
      <form onSubmit={onSubmit} noValidate className="w-100 z-2">
        <div className="d-grid gap-3 mb-3">
          <CheckboxGroup termsData={termsData} handleChange={handleChange} />

          {isFailure && (
            <div className="d-block invalid-feedback">
              {t("error.regular_message")}
            </div>
          )}
        </div>
        <ModalButtonsWrapper>
          <Button
            data-testid="terms-conditions-agree"
            isLoading={isLoading}
            className="btn btn-primary"
            type="submit"
          >
            {t("terms_conditions.continue_button")}
          </Button>
          <Button variant="link" onClick={onClose} type="button">
            {t("terms_conditions.cancel_button")}
          </Button>
        </ModalButtonsWrapper>
      </form>
    }
  >
    <div className="mb-3">
      <p>{TERMS_CONDITIONS_LAST_UPDATED}</p>
    </div>
    <div className="terms-accordion z-1">
      <TermAndConditionsDashboardComponent isContainedInModal />
    </div>
  </GenericBaseModal>
);

export default AcceptTCModal;
