import Checkbox from "../../atoms/checkbox";
import { translate as t } from "../../../helpers/i18n";
import { ChangeEvent } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

export type TermsData = {
  agreement_terms: boolean;
  electronic_signatures: boolean;
  credit_reports: boolean;
  marketing_telephone_communication: boolean;
};

type Props = {
  termsData: TermsData;
  handleChange: (
    e: ChangeEvent<HTMLInputElement>,
    currentValue: boolean
  ) => void;
};

const CheckboxGroup = ({ termsData, handleChange }: Props) => {
  return (
    <div className="w-100">
      <div className="bg-primary-01 border border-primary-02">
        <Checkbox
          checked={termsData.agreement_terms}
          onChange={(e) => handleChange(e, termsData.agreement_terms)}
          name="agreement_terms"
          alignment="center"
          label={t("sign_up.terms_conditions.usage")}
          errorMessage={t("sign_up.terms_conditions.validations.terms_message")}
          className="m-3"
          isTermsData
          required
        />
        <Checkbox
          checked={termsData.electronic_signatures}
          onChange={(e) => handleChange(e, termsData.electronic_signatures)}
          name="electronic_signatures"
          alignment="center"
          label={
            <Trans
              i18nKey={"sign_up.terms_conditions.electronic_signatures"}
              components={{
                pdf: (
                  <Link
                    to="/assets/terms-and-agreement/D_Dashboard_Consent_to_Electronic_Records_Final_September_2024.pdf"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          }
          errorMessage={t("sign_up.terms_conditions.validations.terms_message")}
          className="m-3"
          isTermsData
          required
        />
        <Checkbox
          checked={termsData.credit_reports}
          onChange={(e) => handleChange(e, termsData.credit_reports)}
          name="credit_reports"
          alignment="center"
          label={
            <Trans
              i18nKey={"sign_up.terms_conditions.credit_reports"}
              components={{
                pdf: (
                  <Link
                    to="/assets/terms-and-agreement/E_Dashboard_Consent_to_Obtain_Credit_Reports_Final_September_2024.pdf"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          }
          errorMessage={t("sign_up.terms_conditions.validations.terms_message")}
          className="m-3"
          isTermsData
          required
        />
      </div>
      <Checkbox
        checked={termsData.marketing_telephone_communication}
        name="marketing_telephone_communication"
        onChange={(e) =>
          handleChange(e, termsData.marketing_telephone_communication)
        }
        alignment="center"
        data-testid="check-marketing"
        label={
          <Trans
            i18nKey={
              "sign_up.terms_conditions.receive_telephone_communications"
            }
            components={{
              pdf: (
                <Link
                  to="/assets/terms-and-agreement/F_Dashboard_TCPA_Consent_Final_September_2024.pdf"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        }
        errorMessage={t("sign_up.terms_conditions.validations.terms_message")}
        className="m-3"
        labelSize="small"
        isTermsData
      />
    </div>
  );
};

export default CheckboxGroup;
