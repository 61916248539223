import { Col, Container, Row } from "react-bootstrap";
import { ReactComponent as EqualHousingIcon } from "purple-rain/assets/icons/equal-housing.svg";
import { Theme, useThemeContext } from "../../../contexts/theme-context";
import { cva } from "class-variance-authority";
import { Trans } from "react-i18next";
import { ExternalLink } from "../../../components/external-link";
import { CONSUMER_ACCESS_LINK } from "../../../helpers/constants";

interface Props {
  className?: string;
}

const nmlsFooterIconContainerVariants = cva("", {
  variants: {
    theme: {
      [Theme.PURPLE_RAIN]: "text-muted-blue-700",
      [Theme.LAKEVIEW_BLUE]: "",
    },
  },
});

const nmlsRowContainerByTheme = cva("", {
  variants: {
    theme: {
      [Theme.PURPLE_RAIN]: "",
      [Theme.LAKEVIEW_BLUE]: "px-0",
    },
  },
});

const nmlsDivContainerByTheme = cva("d-flex justify-content-between gap-3", {
  variants: {
    theme: {
      [Theme.PURPLE_RAIN]: "align-items-center",
      [Theme.LAKEVIEW_BLUE]: "align-items-end typeface-body-small",
    },
  },
});

const NMLSFooter = ({ className = "" }: Props) => {
  const { theme } = useThemeContext();

  return (
    <section className={className}>
      <Container className={nmlsRowContainerByTheme({ theme })}>
        <Row>
          <Col>
            <div className={nmlsDivContainerByTheme({ theme })}>
              <span>
                <Trans
                  i18nKey="cash_out_loan_quote.footer"
                  components={{
                    link1: (
                      <ExternalLink
                        module="unauthenticated-dashboard-lakeview-blue"
                        button="lakeview"
                        href={CONSUMER_ACCESS_LINK}
                        targetBlank
                      />
                    ),
                  }}
                />
              </span>
              <span>
                <EqualHousingIcon
                  className={nmlsFooterIconContainerVariants({ theme })}
                />
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NMLSFooter;
