import React from "react";
import { translate as t } from "../../../helpers/i18n";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import TermsAndConditionsModal from "../terms-and-conditions-modal";
import { useSSOValues } from "../../../hooks/use-sso-values";
import { useDashboardVersion } from "../../../hooks";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import PasswordForm from "../../molecules/password-form/password-form";

interface PasswordBoxProps {
  onDismiss: () => void;
}

const PasswordBox = ({ onDismiss }: PasswordBoxProps) => {
  const { openTermsModal, confirmedPassword } = useSSOValues();
  const { user } = useSelector((state: RootState) => state.user);
  const { mixpanelPageName } = useDashboardVersion();

  const handleSubmit = (isFormValid: boolean, password: string) => {
    if (isFormValid) {
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        module: "sso-hero",
        step: 2,
        button: "set-password",
        page: mixpanelPageName,
      });

      openTermsModal(password);
    }
  };

  return (
    <>
      <TermsAndConditionsModal
        password={confirmedPassword}
        isUncompletedProfile
      />
      <PasswordForm
        email={user?.email as string}
        eyebrow={t("sso_banner.password_box.create_your_password")}
        saveButtonText={t("sso_banner.password_box.save")}
        cancelButtonText={t("sso_banner.password_box.cancel")}
        onSubmit={handleSubmit}
        onDismiss={onDismiss}
      />
    </>
  );
};

export default PasswordBox;
