const ConsentToElectronicRecords = () => (
  <>
    <p className="mb-s24">
      <b>
        <i>Lakeview Powered by Fastlane</i> Dashboard Consent to Electronic
        Records and Use of Electronic Communications
      </b>
    </p>
    <p className="mb-s24">
      <b>Communicating with You and Using Electronic Records</b>
    </p>
    <p className="mb-4">
      <span className="fw-bold">Consent to Electronic Records.</span> As part of
      your relationship with Lakeview Loan Servicing, LLC, and its respective
      affiliates, subsidiaries, and service providers, including Fastlane
      Technology Solutions, LLC, (collectively, “we,” “us,” and “our”), we are
      required by law to give you certain information in writing on paper. With
      your consent, we can provide this information to you by electronic
      delivery instead. By providing your consent, you agree to receive
      electronically any disclosure, notice, document, or communication
      (collectively, “Record”)
    </p>
    <p className="mb-4">
      <span className="fw-bold">Scope of Consent.</span> Your consent applies to
      any Record related to any of the Products or Services (as described in the{" "}
      <i>Lakeview Powered by Fastlane</i> Dashboard or other applicable Terms of
      Use) we may make accessible or available, or offer to you, whether through
      a website, software application, email, messaging services (including text
      messages, as permissible by law), or otherwise. Your consent includes, but
      is not limited to, your <i>Lakeview Powered by Fastlane</i> Dashboard.
      This consent means that, as applicable, each time you use this Dashboard
      and submit information to us, you agree to the online access and
      acceptance of documents, disclosures, and forms. This agreement to conduct
      transactions by electronic means continues in effect until you complete
      the application regardless of the number of sessions you utilize.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Delivery of Records.</span> In our sole
      discretion, the Records we provide to you, or that you sign or agree to at
      our request, may be in electronic form. We may also use electronic
      signatures and obtain them from you as part of our transactions with you.
      Records may be delivered to you by: (i) posting on a website, (ii)
      emailing to you at the email address you provide to us, (iii) mobile
      application, (iv) accessing an online location that we designate in an
      e-mail, or other electronic notice we send to you at the time the Records
      is available, or (v) other means we make available to you. Records may
      contain important information or disclosures concerning your financial
      accounts or my use of the Products or Services, and you agree to review
      such Records promptly after we provide them to you.
    </p>
    <p className="mb-4">
      We may always, in our sole discretion, provide you with any Record in
      writing or send it to you via the U.S. mail or other means of delivery,
      even if you have chosen to receive it electronically. We may require any
      information you provide to us, or any document you sign, to be delivered
      to us in paper form. You should print or download a copy of any Records
      for your own records, including this Disclosure and Consent.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Paper Copies.</span> If we provide an electronic
      Record to you, and you would like a paper copy, you may contact customer
      service at fastlanesupport@lakeview.com and request a paper version. You
      will not be charged a fee for a paper copy. We may not retain copies of
      Records for longer than is required by law.
    </p>
    <p className="mb-3">
      <span className="fw-bold">Hardware and Software Requirements. </span> To
      electronically access and retain the Records, you must satisfy the
      following computer hardware and software requirements:
    </p>
    <ul className="mb-4">
      <li>A secure access to the internet;</li>
      <li>
        A secure email account and related software capable of receiving email
        through the internet;
      </li>
      <li>
        Supported Web browsing software (such as the most recent version of
        Google Chrome, Mozilla Firefox, Microsoft Edge, or Apple Safari);
      </li>
      <li>
        Software that allows you to view and print or save PDF documents (such
        as Adobe Reader or similar software);
      </li>
      <li>
        An operating system and hardware capable of supporting the above
        software requirements; and
      </li>
      <li>
        Sufficient storage space or a printer that allows you to save or print
        electronic Records.
      </li>
    </ul>
    <p className="mb-4">
      If our hardware or software requirements change, and that change would
      create a material risk that you would not be able to access or retain
      electronic Records, we will notify you as required by law.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Updating Your Contact Information.</span> You
      agree that you will promptly inform us of any changes to your email
      address, phone number or mailing address by updating your{" "}
      <i>Lakeview Powered by Fastlane</i> Dashboard details or by contacting us
      at fastlanesupport@lakeview.com.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Withdrawing Consent.</span> You may withdraw
      your consent to receive Records electronically by contacting us at
      fastlanesupport@lakeview.com. Note, if you do so, we may terminate your{" "}
      <i>Lakeview Powered by Fastlane</i> Dashboard. Withdrawing your consent to
      receive <i>Lakeview Powered by Fastlane</i> Dashboard documents
      electronically will not affect any other consent you may have provided to
      us or a third party to provide Records to you electronically, such as in
      connection with a loan, insurance application, or account. Please refer to
      those appropriate Terms and Conditions for directions on how to withdraw
      consent.
    </p>
    <p className="mb-4">
      If, after withdrawing your consent to receive Records electronically for
      your <i>Lakeview Powered by Fastlane</i> Dashboard, you wish to continue
      with an application for credit, please follow the instructions below:
    </p>
    <ul>
      <li>
        For a <span className="fw-bold">Home Equity Loan application</span>, you
        will not be able to proceed with Lakeview’s Digital HELOAN application
        process. Please reach out to a loan officer at (855) 713-2644 for
        instructions on how to resume your application via the Lakeview
        application portal instead.
      </li>
      <li>
        For <span className="fw-bold">all other loan applications,</span> please
        visit the Lakeview application portal directly at
        <a>https://apply.lakeview.com</a>, or reach out to a Lakeview loan
        officer (855) 294-8564 for instructions.
      </li>
    </ul>
    <p className="mb-3">
      <span className="fw-bold">
        By providing your consent, you are consenting to the use of electronic
        records and signatures in connection with your relationship with us, and
        also confirming that:
      </span>
    </p>
    <ul className="mb-4">
      <li>
        <span className="fw-bold">
          You have reviewed and agree to the terms of this Disclosure and
          Consent,
        </span>
      </li>
      <li>
        <span className="fw-bold">
          You have the hardware and software described above,
        </span>
      </li>
      <li>
        <span className="fw-bold">
          You are able to receive and review electronic Records, and
        </span>
      </li>
      <li>
        <span className="fw-bold">
          You have an active email account and have provided the correct address
          to us.
        </span>
      </li>
    </ul>
  </>
);

export default ConsentToElectronicRecords;
