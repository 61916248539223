import { cx, VariantProps } from "class-variance-authority";
import { ReactNode } from "react";
import { useThemeContext } from "../../../contexts/theme-context";
import {
  buttonStyles,
  defaultThemeCVA,
  lakeviewBlueThemeCTA,
} from "./buttonStyles";

type ButtonStyleProps = VariantProps<
  typeof defaultThemeCVA | typeof lakeviewBlueThemeCTA
>;

export interface ButtonProps
  extends ButtonStyleProps,
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color"> {
  children: ReactNode;
  icon?: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
}

const Button = ({
  variant = "solid",
  size = "md",
  children,
  icon,
  className = "",
  isLoading = false,
  disabled = false,
  fullWidth = false,
  color,
  ...rest
}: ButtonProps) => {
  const { theme } = useThemeContext();
  const spinnerClass = `spinner spinner-${size} spinner-${variant}`;
  const isButtonDisabled = disabled || isLoading;

  return (
    <button
      className={cx(
        buttonStyles[theme]({ variant, color, size, isLoading, fullWidth }),
        className
      )}
      disabled={isButtonDisabled}
      {...rest}
    >
      {isLoading && <span role="status" className={spinnerClass} />}
      {icon}
      {children}
    </button>
  );
};

export { Button, Button as default };
