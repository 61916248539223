import dashApi from "../helpers/dash-api";
import {
  AsyncStatus,
  DACSignupInfo,
  DisclaimerAcceptancesMap,
  MortgageSearchResult,
} from "../types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Endpoints
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const getDACSignUpInfo = dashApi
  .path("/accounts/v1/users/signup-info")
  .method("get")
  .create();

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// State
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export interface DACSignupInfoState {
  user: DACSignupInfo | undefined;
  status: AsyncStatus;
  initialized: boolean;
}

export interface DACUserFlowState {
  zipCode?: string;
  loanLookupEmail?: string;
  selectedLoan?: MortgageSearchResult;
  disclaimerAcceptances?: DisclaimerAcceptancesMap;
  password?: string;
  nonce?: string;
}

const initialDACUserFlowState: DACUserFlowState = {
  zipCode: undefined,
  loanLookupEmail: undefined,
  selectedLoan: undefined,
  disclaimerAcceptances: undefined,
  password: undefined,
  nonce: undefined,
};

const initialDACSignupInfoState: DACSignupInfoState = {
  user: undefined,
  status: "idle",
  initialized: false,
};

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// API Calls
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const loadDACSignUpInfo = createAsyncThunk(
  "DACSignUpInfo/loadDACSignUpInfo",
  async function (nonce: string) {
    const response = await getDACSignUpInfo(
      {},
      {
        headers: {
          nonce: nonce,
        },
      }
    );

    return response.data;
  }
);

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Slices
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const DACUserFlowSlice = createSlice({
  name: "DACUserFlow",
  initialState: initialDACUserFlowState,
  reducers: {
    updateDACUserFlow(state, action: PayloadAction<DACUserFlowState>) {
      const payload = action.payload as Partial<DACUserFlowState>;

      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const DACSignupInfoSlice = createSlice({
  name: "DACSignupInfo",
  initialState: initialDACSignupInfoState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadDACSignUpInfo.pending, (state) => {
        if (!state.initialized) {
          state.status = "loading";
        }
      })
      .addCase(loadDACSignUpInfo.fulfilled, (state, action) => {
        state.status = "loaded";
        state.initialized = true;
        state.user = action.payload as DACSignupInfo;
      })
      .addCase(loadDACSignUpInfo.rejected, (state) => {
        if (!state.initialized) {
          state.status = "failed";
          state.user = undefined;
        }
      });
  },
});

export const { updateDACUserFlow } = DACUserFlowSlice.actions;
