import { Trans } from "react-i18next";
import Button from "../atomic/atoms/button/index";
import { translate as t } from "../helpers/i18n";
import React, { cloneElement, ReactElement, useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { pages } from "../helpers/pages";
import { Link } from "react-router-dom";
import { useDashboardVersion, useMortgageValues } from "../hooks";
import { useSelector } from "react-redux";
import { RootState } from "../stores";
import { getSubservicerShortName } from "../helpers/subservicer";
import GenericBaseModal from "../atomic/organisms/modal/generic-base-modal";
import ModalButtonsWrapper from "../atomic/organisms/modal/components/modal-buttons-wrapper";

const MODULE = "leave-dialog";

const proceedEventButtonNames: {
  [key in ConfirmLeaveModalType]: (subservicerShortName?: string) => string;
} = {
  subservicer_link: (subservicerShortName?: string) =>
    `proceed-to-${subservicerShortName}`,
  application_portal: () => "proceed-to-lakeview",
  lhis_insurance_ad: () => "proceed-to-lhis",
};

export type ConfirmLeaveModalType =
  | "subservicer_link"
  | "application_portal"
  | "lhis_insurance_ad";

export interface LakeviewEventParams {
  module?: string;
  product?: string;
  button?: string;
  step?: string;
  state?: string;
  offer_type?: string;
  path?: string;
  link?: string;
  view_source?: string;
}

interface ConfirmLeaveModalProps {
  modalType: ConfirmLeaveModalType;
  children?: ReactElement;
  show?: boolean;
  href?: string;
  isLoading?: boolean;
  loadingMessage?: string;
  onClick?: () => void;
  onShowModal?: () => void;
  viewSource: string;
  eventParams?: LakeviewEventParams;
}

const ConfirmLeaveModal = ({
  modalType,
  children,
  href,
  show = false,
  isLoading = false,
  loadingMessage,
  onClick,
  onShowModal,
  viewSource,
  eventParams,
}: ConfirmLeaveModalProps) => {
  const { user: userState } = useSelector((state: RootState) => state);
  const { mixpanelPageName } = useDashboardVersion();

  const [showLeavingFastlaneModal, setShowLeavingFastlaneModal] =
    useState(false);

  useEffect(() => {
    setShowLeavingFastlaneModal(show as boolean);
  }, [show]);

  const { account } = useMortgageValues();
  const subservicerName =
    account?.subservicer?.title || t("home_value.generic_subservicer");
  const subservicerShortName = getSubservicerShortName(userState.user);

  const proceedEventButtonName =
    proceedEventButtonNames[modalType](subservicerShortName);

  const handleCloseModal = () => setShowLeavingFastlaneModal(false);

  const handleShowModal = (e: React.MouseEvent) => {
    e.preventDefault();
    onShowModal?.();

    // Track the event of clicking the open modal button
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      ...eventParams,
      module: viewSource,
      page: mixpanelPageName,
    });

    // Track the event of clicking the open modal button
    mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
      module: MODULE,
      view_source: viewSource,
    });
    setShowLeavingFastlaneModal(true);
  };

  const handleClick = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      ...eventParams,
      module: MODULE,
      button: proceedEventButtonName,
      page: mixpanelPageName,
    });

    onClick?.();
    handleCloseModal();
  };

  const handleClose = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: MODULE,
      button: "cancel",
    });

    handleCloseModal();
  };

  // This function is called when user clicks on the `X` button on top right of the modal
  const handleCloseButtonClick = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: MODULE,
      button: "close",
    });

    handleCloseModal();
  };

  const handleBackdropClick = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: MODULE,
      button: "off_module_click_to_close",
    });

    handleCloseModal();
  };

  const handleTermsClick = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: MODULE,
      button: "terms_and_conditions",
    });
  };

  // This function delete the focus button and avoid the issues between modals and offcanvas
  const handleFocus = (e: React.FocusEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      {children &&
        cloneElement(children, {
          onClick: handleShowModal,
          onFocus: handleFocus,
        })}
      <GenericBaseModal
        show={showLeavingFastlaneModal}
        title={t(`leaving_modal.${modalType}.title`)}
        onClose={handleBackdropClick}
        onCloseButtonClick={handleCloseButtonClick}
        isLoading={isLoading}
        loadingMessage={loadingMessage}
        buttons={
          <ModalButtonsWrapper>
            <a
              className="text-decoration-none"
              onClick={handleClick}
              target="_blank"
              rel="noreferrer"
              {...(!!href ? { href } : {})}
            >
              <Button className="w-100 w-md-auto" color="primary">
                {t(`leaving_modal.${modalType}.cta`)}
              </Button>
            </a>
            <Button
              onClick={handleClose}
              className="text-decoration-none"
              variant="link"
            >
              {t(`leaving_modal.${modalType}.cancel`)}
            </Button>
          </ModalButtonsWrapper>
        }
      >
        <Trans
          i18nKey={`leaving_modal.${modalType}.content`}
          components={{
            linkTag: (
              <Link to={pages.termsConditions} onClick={handleTermsClick} />
            ),
            lhisBold: <span className="fw-bold" />,
          }}
          values={{
            subservicer_name: subservicerName,
          }}
          shouldUnescape
        />
      </GenericBaseModal>
    </>
  );
};

export default ConfirmLeaveModal;
