import { ReactComponent as StandaloneLogoLakeviewSVG } from "purple-rain/assets/standalone-logo-lakeview.svg";
import { ReactComponent as LogoLakeviewUnauthenticatedRebrandSVG } from "purple-rain/assets/logo-lakeview-unauthenticated-rebrand.svg";
import { translate as t } from "../../../helpers/i18n";
import { Link } from "react-router-dom";
import { pages } from "../../../helpers/pages";
import LegalDisclaimer from "./legal-disclaimer";
import FooterBase from "./footer-base";
import useStandaloneMode from "../../../hooks/use-standalone";
import { EXPERIMENTS, useABTest } from "../../../hooks/use-ab-test";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import {
  Theme,
  ThemeType,
  useThemeContext,
} from "../../../contexts/theme-context";
import { cva, cx } from "class-variance-authority";
import { useDashboardVersion } from "../../../hooks";
import { DashboardType } from "../../../types";
import {
  experimentManualExposure,
  NewExperimentNameGroup,
  NoneExperimentGroup,
} from "../../../helpers/experiment-exposure";

const MIXPANEL_MODULE_NAME = "footer";

interface ListItem {
  translationKey: string;
  path: string;
  mixpanelButtonName?: string;
}

const PRODUCT_LIST = (includeHomeEquityLoan?: boolean): ListItem[] => [
  // Cash Out Refinance
  {
    translationKey: "layout.products_list.cash_out_refinance.secondary_title",
    path: pages.products.cashOutRefinance,
    mixpanelButtonName: "go-to-cashout-pdp",
  },

  {
    translationKey: "layout.products_list.home_equity_loan.secondary_title",
    path: includeHomeEquityLoan
      ? pages.products.homeEquityLoan
      : pages.products.cashOutRefinance,
    mixpanelButtonName: "go-to-heloan-pdp",
  },

  // Get a Mortgage
  {
    translationKey: "layout.products_list.get_mortgage.secondary_title",
    path: pages.products.getAMortgage,
    mixpanelButtonName: "go-to-mortgage-pdp",
  },
  // Rate Term Refinance
  {
    translationKey: "layout.products_list.rate_term_refinance.secondary_title",
    path: pages.products.rateTermRefinance,
    mixpanelButtonName: "go-to-rtr-pdp",
  },
];

const RESOURCES_LIST: ListItem[] = [
  {
    translationKey: "layout.footer.resource_hub",
    path: pages.resourceCenter,
  },
  {
    translationKey: "layout.footer.faq_and_support",
    path: pages.frequentlyAskedQuestions,
  },
  {
    translationKey: "layout.footer.terms_and_conditions",
    path: pages.termsConditions,
  },
];

const productListContainerItemContentVariants = cva(
  "d-flex flex-column  align-items-start",
  {
    variants: {
      theme: {
        [Theme.PURPLE_RAIN]: "gap-3",
        [Theme.LAKEVIEW_BLUE]: "gap-2 gap-md-3",
      },
    },
  }
);

const productListItemContentVariants = cva("", {
  variants: {
    theme: {
      [Theme.PURPLE_RAIN]: "text-body-01 text-indigo-2",
      [Theme.LAKEVIEW_BLUE]: "",
    },
  },
});

const renderItemList = (
  itemList: ListItem[],
  mixpanelPageName: `/${DashboardType}`,
  theme: ThemeType,
  groupName: NewExperimentNameGroup | NoneExperimentGroup = "None"
) => {
  const handleClick = (item: ListItem) => {
    if (item.mixpanelButtonName) {
      const link = `${window.location.origin}${item.path}`;

      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        module: MIXPANEL_MODULE_NAME,
        button: item.mixpanelButtonName,
        page: mixpanelPageName,
        link,
      });
    }
    if (
      t(item.translationKey) ===
      t("layout.products_list.home_equity_loan.secondary_title")
    ) {
      experimentManualExposure(EXPERIMENTS.TAVANT_DIGITAL_HELOAN, groupName, [
        "Control1",
        "Treatment1",
      ]);
    }
  };

  return (
    <div className={productListContainerItemContentVariants({ theme })}>
      {itemList.map((item, index) => (
        <Link
          to={item.path}
          key={item.translationKey}
          className={cx(
            productListItemContentVariants({ theme }),
            `${index === 0 ? "m-0" : ""}`
          )}
          onClick={() => handleClick(item)}
        >
          {t(item.translationKey)}
        </Link>
      ))}
    </div>
  );
};

const Footer = () => {
  const isStandalone = useStandaloneMode();
  const { isExperimentAvailable: isHeloanHomeEquityLoanActive, groupName } =
    useABTest(EXPERIMENTS.TAVANT_DIGITAL_HELOAN);
  const { mixpanelPageName } = useDashboardVersion();
  const { theme } = useThemeContext();

  const titleContentVariants = cva("col mb-3  fw-bold", {
    variants: {
      theme: {
        [Theme.PURPLE_RAIN]: "text-caption text-indigo-2",
        [Theme.LAKEVIEW_BLUE]: "text-uppercase typeface-cta-small",
      },
    },
  });
  const iconLakeviewLogo = (theme: ThemeType) => {
    const iconByTheme = {
      [Theme.PURPLE_RAIN]: (
        <StandaloneLogoLakeviewSVG width={250} height="100%" />
      ),
      [Theme.LAKEVIEW_BLUE]: (
        <LogoLakeviewUnauthenticatedRebrandSVG width={110} height="100%" />
      ),
    };

    return iconByTheme[theme] || iconByTheme[Theme.PURPLE_RAIN];
  };

  if (isStandalone) return <footer className="bg-primary w-full py-2" />;

  return (
    <FooterBase includeDisclosures>
      <div className="d-flex flex-column flex-md-row w-100 gap-5">
        <div className="d-flex flex-column flex-xl-row w-100 gap-6 gap-xl-7 text-body-02">
          <div className="pe-xl-5">{iconLakeviewLogo(theme)}</div>
          <div className="d-flex flex-column flex-md-row w-100 gap-5">
            <div className="w-100">
              <div className={titleContentVariants({ theme })}>
                {t("layout.footer.products_title")}
              </div>
              {renderItemList(
                PRODUCT_LIST(isHeloanHomeEquityLoanActive),
                mixpanelPageName,
                theme,
                groupName
              )}
            </div>
            <div className="w-100">
              <div className={titleContentVariants({ theme })}>
                {t("layout.footer.resources_title")}
              </div>
              <div className="d-flex flex-column gap-3 align-items-start text-body-02">
                {renderItemList(RESOURCES_LIST, mixpanelPageName, theme)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className="w-100 text-light-pink opacity-100 m-0" />

      {/* Legal disclaimer */}
      <LegalDisclaimer />
    </FooterBase>
  );
};

export default Footer;
