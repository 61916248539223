import Disclosures from "../disclosures";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";

interface FooterBaseProps {
  children: React.ReactNode;
  includeDisclosures?: boolean;
}

const FooterBase = ({ children, includeDisclosures }: FooterBaseProps) => {
  const { heroButtonsVisible } = useSelector((state: RootState) => state.app);

  const extraSpaceClasses = !heroButtonsVisible
    ? "pb-7 mb-7 pb-md-7 mb-md-6"
    : "";

  return (
    <footer
      className={`position-relative bg-primary-01 overflow-hidden ${extraSpaceClasses}`}
    >
      {includeDisclosures ? <Disclosures /> : null}
      <div className="position-relative">
        <div className="container d-flex flex-column align-items-start gap-6 position-relative py-5 pb-md-6 pt-md-7">
          {children}
        </div>
      </div>
    </footer>
  );
};

export default FooterBase;
