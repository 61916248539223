import { cva } from "class-variance-authority";
import { Theme } from "../../../contexts/theme-context";

export const defaultThemeCVA = cva(
  "btn align-items-center gap-2 justify-content-center d-flex gap-md-12px text-nowrap",
  {
    variants: {
      variant: {
        solid: "",
        ghost: "",
        outline: "",
        link: "btn-link",
      },
      color: {
        primary: "btn-primary",
        secondary: "btn-secondary",
        default: "",
      },
      size: {
        lg: "btn-lg",
        md: "btn-md",
        sm: "btn-sm",
      },
      isLoading: {
        true: "btn-is-loading",
        false: "d-flex",
      },
      fullWidth: {
        true: "w-100",
        false: "",
      },
    },
    compoundVariants: [
      {
        variant: "solid",
        color: "primary",
        class: "text-white",
      },
      {
        variant: "ghost",
        class: "btn-ghost",
      },
      {
        variant: "ghost",
        color: "primary",
        class: "btn-ghost-primary",
      },
      {
        variant: "outline",
        color: "primary",
        class: "btn-outline-primary",
      },
      {
        variant: "solid",
        color: "secondary",
        class: "btn-secondary text-white",
      },
      {
        variant: "ghost",
        color: "secondary",
        class: "btn-ghost-secondary",
      },
      {
        variant: "outline",
        color: "secondary",
        class: "btn-outline-secondary",
      },
    ],
    defaultVariants: {
      variant: "solid",
      size: "md",
      color: "default",
      isLoading: false,
      fullWidth: false,
    },
  }
);
export const lakeviewBlueThemeCTA = cva(
  "btn d-flex justify-content-center align-items-center gap-2 gap-md-12px text-nowrap py-3",
  {
    variants: {
      variant: {
        solid: "",
        ghost: "",
        outline: "",
        link: "btn-link",
      },
      color: {
        primary: "btn-primary",
        secondary: "btn-secondary",
        default: "",
      },
      size: {
        lg: "btn-lg",
        md: "btn-md",
        sm: "btn-sm",
      },
      isLoading: {
        true: "btn-is-loading",
        false: "d-flex",
      },
      fullWidth: {
        true: "w-100",
        false: "",
      },
    },
    compoundVariants: [
      {
        variant: "solid",
        color: "primary",
        class: "text-white",
      },
      {
        variant: "ghost",
        class: "btn-ghost",
      },
      {
        variant: "ghost",
        color: "primary",
        class: "btn-ghost-primary",
      },
      {
        variant: "outline",
        color: "primary",
        class: "btn-outline-primary",
      },
      {
        variant: "solid",
        color: "secondary",
        class: "btn-secondary text-white",
      },
      {
        variant: "ghost",
        color: "secondary",
        class: "btn-ghost-secondary",
      },
      {
        variant: "outline",
        color: "secondary",
        class: "btn-outline-secondary",
      },
    ],
    defaultVariants: {
      variant: "solid",
      size: "md",
      color: "default",
      isLoading: false,
      fullWidth: false,
    },
  }
);

export const buttonStyles = {
  [Theme.PURPLE_RAIN]: defaultThemeCVA,
  [Theme.LAKEVIEW_BLUE]: lakeviewBlueThemeCTA,
};
