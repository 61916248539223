import Accordion from "react-bootstrap/Accordion";
import ArbitrationAgreement from "./arbitration-agreement";
import ConsentToElectronicRecords from "./consent-to-electronic-records";
import ConsentToObtainCreditReport from "./consent-to-obtain-credit-reports";
import ConsentToReceiveTelephoneComms from "./consent-to-receive-telephone-comms";
import PrivacyPolicy from "./privacy-policy";
import TermsOfUse from "./terms-of-use";

interface Props {
  isContainedInModal?: boolean;
}

const TermAndConditionsDashboardComponent = ({
  isContainedInModal = false,
}: Props) => {
  const accordionClassName = isContainedInModal ? "" : "mb-4";
  const lastAccordionItemClassName = isContainedInModal
    ? "border-bottom-0"
    : "";

  const modalHeaderStyle = isContainedInModal ? "text-body-01 fw-normal" : "";

  return (
    <Accordion
      className={`forms-accordion ${accordionClassName}`}
      id="termsConditions"
      data-testid="terms-conditions-section-dashboard"
      alwaysOpen
    >
      <Accordion.Item eventKey="headingOne">
        <Accordion.Header id="headingOne" className={modalHeaderStyle}>
          A. Terms of Use
        </Accordion.Header>
        <Accordion.Body className="accordion-body p-0">
          <TermsOfUse />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="headingTwo">
        <Accordion.Header id="headingTwo" className={modalHeaderStyle}>
          B. Privacy Policy
        </Accordion.Header>
        <Accordion.Body className="p-0">
          <PrivacyPolicy />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="headingThree">
        <Accordion.Header id="headingThree" className={modalHeaderStyle}>
          C. Arbitration Agreement
        </Accordion.Header>
        <Accordion.Body className="p-0">
          <ArbitrationAgreement />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="headingFour">
        <Accordion.Header
          id="headingFour"
          className={`accordion-header ${modalHeaderStyle}`}
        >
          D. Consent to Electronic Records
        </Accordion.Header>
        <Accordion.Body className="p-0">
          <ConsentToElectronicRecords />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="headingFive">
        <Accordion.Header id="headingFive" className={modalHeaderStyle}>
          E. Consent to Obtain Credit Reports
        </Accordion.Header>
        <Accordion.Body className="p-0">
          <ConsentToObtainCreditReport />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item
        eventKey="headingSix"
        className={lastAccordionItemClassName}
      >
        <Accordion.Header
          className={`accordion-header ${modalHeaderStyle}`}
          id="headingSix"
        >
          F. Consent to Receive Telephone Communications
        </Accordion.Header>
        <Accordion.Body className="p-0">
          <ConsentToReceiveTelephoneComms />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default TermAndConditionsDashboardComponent;
